<template>
  <div>
    <el-dialog :visible.sync="establishFlag" width="60%" :show-close="false">
      <div
        slot="title"
        style="display:flex;justify-content: space-between;font-size:13px;"
      >
        <span style="font-weight:bolder;font-size:20px;">创建媒体投放</span>
        <span v-show="!buttonFlag">
          当前选择的进线日期{{ CreatedForm.date }}
        </span>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%"
        v-loading="loading"
        v-if="tableFlag"
      >
        <el-table-column prop="id" label="选项" width="100">
          <template slot-scope="scope">
            <el-radio
              v-model="radio"
              :label="scope.row.id"
              @change="changeRadio(scope.row)"
            />
          </template>
        </el-table-column>
        <el-table-column prop="date" label="进线日期" width="auto">
        </el-table-column>
        <el-table-column prop="from" label="客户来源" width="auto">
        </el-table-column>
        <el-table-column prop="count" label="进线数量" width="auto">
        </el-table-column>
        <el-table-column prop="unique_count" label="去重进线" width="auto">
        </el-table-column>
      </el-table>
      <el-form label-width="100px" :model="CreatedForm" v-else>
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-form-item label="投放媒体" :required="true">
            <el-input v-model="CreatedForm.from" disabled></el-input>
          </el-form-item>
          <el-form-item label="投放渠道" :required="true">
            <el-select
              v-model="CreatedForm.channel_id"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in channelList"
                :key="item.channel_id"
                :label="item.channel"
                :value="item.channel_id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="现金消耗">
            <el-input v-model="CreatedForm.cost" type="number"></el-input>
          </el-form-item>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-form-item label="服务费用">
            <el-input
              v-model="CreatedForm.service_amount"
              type="number"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="账户返点">
            <el-input
              v-model="CreatedForm.account_rebate"
              type="number"
            ></el-input>
          </el-form-item>
          <el-form-item label="进线数量">
            <el-input v-model="CreatedForm.count" disabled></el-input>
          </el-form-item>
        </el-row>
        <el-row type="flex" class="row-bg" justify="space-between">
          <el-form-item label="去重进线">
            <el-input v-model="CreatedForm.unique_count" disabled></el-input>
          </el-form-item>
          <el-form-item label="投放金额">
            <el-input v-model="CreatedForm.throw_amount" disabled></el-input>
          </el-form-item>
          <el-form-item label="进线成本">
            <el-input v-model="CreatedForm.thread_cost" disabled></el-input>
          </el-form-item>
        </el-row>
        <el-row type="flex" class="row-bg" justify="start">
          <el-form-item label="去重成本">
            <el-input v-model="CreatedForm.unique_cost" disabled></el-input>
          </el-form-item>
          <el-col
            ><el-form-item label="投放摘要">
              <el-input
                type="textarea"
                :rows="4"
                placeholder="请输入内容"
                v-model="CreatedForm.remark"
              >
              </el-input> </el-form-item
          ></el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancel" size="mini">取 消</el-button>
        <el-button
          type="primary"
          @click="handleNext"
          size="mini"
          v-if="buttonFlag"
          >下一步</el-button
        >
        <el-button type="primary" @click="handleIssue" size="mini" v-else
          >发 布</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getThrowCountList, postThreadThrow } from "@/api/media";
export default {
  name: "createdOnThe",
  data() {
    return {
      CreatedForm: {},
      tableFlag: true,
      loading: false,
      buttonFlag: true,
      establishFlag: false,
      radio: "0",
      tableData: [],
      options: [],
      time: null,
    };
  },
  //监视buttonFlag
  watch: {
    establishFlag: function(val) {
      if (val) {
        this.fromData();
      }
    },
    //监视this.CreatedForm
    CreatedForm: {
      handler: function(val) {
        if (val) {
          this.CreatedForm.service_amount = (
            Number(this.CreatedForm.cost) * 0.05
          ).toFixed(2); //服务费用
          this.CreatedForm.throw_amount = (
            Number(this.CreatedForm.cost) +
            Number(this.CreatedForm.service_amount)
          ).toFixed(2); //投放金额

          this.CreatedForm.unique_cost = (
            Number(this.CreatedForm.throw_amount) /
            Number(this.CreatedForm.unique_count)
          ).toFixed(2); //去重成本
          this.CreatedForm.thread_cost = (
            Number(this.CreatedForm.throw_amount) /
            Number(this.CreatedForm.count)
          ).toFixed(2); //进线成本
        }
        //投放金额，进线成本，去重成本为NaN时，赋值为0
        if (
          isNaN(this.CreatedForm.throw_amount) ||
          isNaN(this.CreatedForm.thread_cost) ||
          isNaN(this.CreatedForm.unique_cost)
        ) {
          this.CreatedForm.throw_amount = 0.0;
          this.CreatedForm.unique_cost = 0.0;
          this.CreatedForm.thread_cost = 0.0;
        }
        //投放金额，进线成本，去重成本为Infinity时，赋值为0
        if (
          this.CreatedForm.throw_amount == Infinity ||
          this.CreatedForm.thread_cost == Infinity ||
          this.CreatedForm.unique_cost == Infinity
        ) {
          this.CreatedForm.throw_amount = 0.0;
          this.CreatedForm.unique_cost = 0.0;
          this.CreatedForm.thread_cost = 0.0;
        }
      },
      deep: true,
    },
  },
  methods: {
    //取消
    handleCancel() {
      console.log(this.$emit('getList'));
      this.$emit('getList')
      this.establishFlag = false;
      this.tableData = [];
      this.buttonFlag = true;
      this.tableFlag = true;
      this.CreatedForm = "";
      this.radio = "0";
    },
    //下一步
    handleNext() {
      if (this.CreatedForm.id) {
        this.buttonFlag = false;
        this.tableFlag = false;
      } else {
        this.$message({
          message: "您还没有选择,无法进行下一步哦",
          type: "warning",
        });
      }
    },
    fromData() {
      this.loading = true;
      getThrowCountList().then((res) => {
        if (res.code == 200) {
          res.data.forEach((item, index) => {
            item.id = index + 1;
          });
          this.tableData = res.data;
        }
        this.loading = false;
      });
    },
    changeRadio(row) {
      this.CreatedForm = row;
      console.log(this.CreatedForm);
    },
    handleIssue() {
      if (!this.CreatedForm.channel_id) {
        this.$message({
          message: "请选择渠道",
          type: "warning",
        });
        return;
      }
      const data = {
        ...this.CreatedForm,
      };
      data.thread_count = this.CreatedForm.count;
      data.channel = this.channelList.find(
        (item) => item.channel_id == this.CreatedForm.channel_id
      ).channel;
      console.log(data);
      postThreadThrow(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "发布成功",
            type: "success",
          });
          this.$emit("getList");
          this.establishFlag = false;
          this.tableData = [];
          this.buttonFlag = true;
          this.tableFlag = true;
          this.CreatedForm = "";
          this.radio = "0";
        }else{
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/ input[type="number"] {
  -moz-appearance: textfield;
}
</style>
