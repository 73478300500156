<template>
  <div class=".gg-container-1">
    <div class="search-container">
      <!--输入框-->
      <div class="search-container-fn-input">
        <label>投放来源：</label>
        <el-select
          size="mini"
          v-model="searchParams.from_id"
          placeholder="请选择投放媒体"
          @change="getList"
          clearable
        >
          <el-option
            v-for="item in fromList"
            :key="item.from_id"
            :value="item.from_id"
            :label="item.from"
          ></el-option>
        </el-select>
      </div>

      <div style="display: flex">
        <div
          style="margin-right:10px; border: #2632fb 1px solid; padding: 2px 6px 4px 6px"
          v-for="item in amountData"
          :key="item.label"
        >
          <span style="font-size: 12px; color: #333"
            >{{ item.name }}: {{ item.money }}{{ item.symbol }}</span
          >
        </div>
      </div>

      <div
        style="margin-left: auto; display: flex; justify-content: space-around"
      >
        <div class="search-container-fn-input"
						 v-if="operateList.indexOf('update') > -1"
				>
          <el-button
            size="mini"
            type="primary"
            icon=""
            style="margin-right: 10px; padding: 6px 7px;background-color: #2362FB; border-color: #2362FB"
            @click="handleEdit(currentSelectItem)"
            >编辑</el-button
          >
        </div>

        <div class="search-container-fn-input"
						 v-if="operateList.indexOf('delete') > -1"
				>
          <el-button
            size="mini"
            type="danger"
            icon=""
            style="margin-right: 10px; padding: 6px 7px;"
            @click="handleDel(currentSelectItem)"
            >删除
          </el-button>
        </div>
        <div v-if="currentSelectItem.examine_status == 0" style="display: flex">
          <div class="search-container-fn-input">
            <el-button
              size="mini"
              type="success"
              icon=""
              style="margin-right: 10px; padding: 6px 7px;"
              @click="handleClick(currentSelectItem)"
              >审核
            </el-button>
          </div>
        </div>
        <div
          class="search-container-fn-input"
					v-if="operateList.indexOf('update_table') > -1"
        >
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
            @click="handleEditTableHead"
            >编辑表头
          </el-button>
        </div>

        <div class="search-container-fn-input"
						 v-if="operateList.indexOf('reset_query') > -1"
				>
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
            @click="handleResetSearch"
            >重置查找
          </el-button>
        </div>
        <!-- <div class="search-container-fn-input">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-plus"
            style="margin-right: 10px; padding: 6px 7px;
                              background-color: #2362FB; border-color: #2362FB"
            @click="handleClick(currentSelectItem)"
            >审核
          </el-button>
        </div> -->
        <!--判断编辑行-->
        <!--	// scope.row.examine_status == 0-->
        <div v-if="Object.keys(currentSelectItem).length == 0"></div>

        <!--对应第三方图标的button样式-->
        <div class="search-container-fn-input"
						 v-if="operateList.indexOf('add') > -1"
				>
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-plus"
            style="margin-right: 10px; padding: 6px 7px;
                              background-color: #2362FB; border-color: #2362FB"
            @click="handleEstablish"
            >创建投放
          </el-button>
        </div>

        <div class="search-container-fn-input"
						 v-if="operateList.indexOf('export') > -1"
				>
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-plus"
            style="margin-right: 10px; padding: 6px 7px;
                              background-color: #2362FB; border-color: #2362FB"
            @click="handleExport"
            >导出
          </el-button>
        </div>
      </div>
    </div>

    <!--    <el-form :inline="true" :model="inquireList" class="demo-form-inline">
      <el-form-item label="投放日期:">
        <el-date-picker
          v-model="inquireList.date"
          type="daterange"
          size="mini"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="changeDate"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="投放来源:">
        <el-select
					size="mini"
          v-model="searchParams.from_id"
          placeholder="请选择投放媒体"
          @change="getList"
          clearable
        >
          <el-option
            v-for="item in fromList"
            :key="item.from_id"
            :value="item.from_id"
            :label="item.from"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="flex_1">
      <div>
        <el-tag
          style="margin-right:20px"
          v-for="item in amountData"
          :key="item.label"
          effect="plain"
          type="info"
        >
          <span style="color:red;"
            >{{ item.name }}: {{ item.money }}{{ item.symbol }}</span
          >
        </el-tag>
      </div>
      <div>
        <el-button type="success" size="mini" @click="handleEstablish"
          >创建投放</el-button
        >
        <el-button type="success" size="mini" @click="handleExport"
          >导出</el-button
        >
      </div>
    </div>
		-->

    <!-- <el-table
      :data="tableData"
      border
      style="width: 100%"
      v-loading="listLoading"
    >
      <el-table-column prop="date" label="投放日期" width="auto">
      </el-table-column>
      <el-table-column prop="id" label="投放编号" width="auto">
      </el-table-column>
      <el-table-column prop="examine_status" label="审核状态" width="auto">
        <template slot-scope="scope">
          <span>
            {{ scope.row.examine_status == 0 ? "待审核" : "已审核" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="diff_amount" label="差异" width="auto">
      </el-table-column>
      <el-table-column prop="channel" label="渠道" width="auto">
      </el-table-column>
      <el-table-column prop="from" label="投放媒体" width="auto">
      </el-table-column>
      <el-table-column prop="account_cost" label="账户消耗" width="auto">
      </el-table-column>
      <el-table-column prop="cost" label="现金消耗" width="auto">
      </el-table-column>
      <el-table-column prop="service_amount" label="服务费用" width="auto">
      </el-table-column>
      <el-table-column prop="throw_amount" label="投放金额" width="auto">
      </el-table-column>
      <el-table-column prop="thread_count" label="进线数量" width="auto">
      </el-table-column>
      <el-table-column prop="account_rebate" label="账户返点" width="auto">
      </el-table-column>
      <el-table-column prop="thread_cost" label="进线成本" width="auto">
      </el-table-column>
      <el-table-column prop="account_amount" label="账户成本" width="auto">
      </el-table-column>
      <el-table-column prop="unique_count" label="去重进线" width="auto">
      </el-table-column>
      &lt;!&ndash; <el-table-column prop="unique_cost" label="含重成本" width="auto">
      </el-table-column> &ndash;&gt;
      &lt;!&ndash; <el-table-column prop="" label="进线验证" width="auto"> </el-table-column> &ndash;&gt;
      <el-table-column prop="repeat_count" label="重线数量" width="auto">
      </el-table-column>
      <el-table-column prop="repeat_rate" label="重复率" width="auto">
      </el-table-column>
      <el-table-column prop="unique_amount" label="去重金额" width="auto">
      </el-table-column>
      <el-table-column prop="remark" label="投放摘要" width="auto">
      </el-table-column>
      <el-table-column prop="created_at" label="审核日期" width="auto">
      </el-table-column>
      <el-table-column prop="examine_user" label="审核人员" width="auto">
      </el-table-column>
      <el-table-column prop="record_user" label="录入人员" width="auto">
      </el-table-column>
      <el-table-column prop="examine_at" label="录入日期" width="auto">
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="120">
        <template slot-scope="scope">
          <div v-if="scope.row.examine_status == 0">
            <el-button @click="handleClick(scope.row)" type="text" size="small"
              >确认</el-button
            >
            <el-button type="text" size="small" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" size="small" @click="handleDel(scope.row.id)"
              >删除</el-button
            >
          </div>
          <div v-else>
            <el-button type="text" size="small" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>-->
    <!----------------表格---------------->
    <el-table
      border
      size="mini"
      v-loading="listLoading"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      height="625"
      highlight-current-row
      @current-change="handleSelectionChange"
      style="width: 100%;z-index:0"
    >
      <el-table-column type="selection" width="55" align="center">
        <template slot-scope="scope">
          <!--<el-checkbox v-model="scope.row.checked" v-show="false"></el-checkbox>-->
          <el-radio v-model="checked" :label="scope.row.id"> </el-radio>
        </template>
      </el-table-column>
      <template v-for="(column, index) in tableHead">
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-if="column.field_type === 'textBtn' && column.visible === true"
          show-overflow-tooltip
        >
          <!--<template #header>
						<el-popover placement="bottom" title="" width="230" trigger="hover">
							<div slot="reference" class="search-header">
								<span class="search-title">{{column.column_label_user_definition ? column.column_label_user_definition : column.column_label}}</span>
								<i style="margin-left: 5px" class="search-icon el-icon-search"></i>
							</div>
							<el-input
									size="mini"
									placeholder=""
									prefix-icon="el-icon-search"
									v-model="searchParams.buy_count"
									clearable
									@change="getList('restPage')"
								>
								</el-input>
						</el-popover>
					</template>-->
          <template slot-scope="scope">
            <a
              style="font-size: 12px; color: #2379fb"
              @click.prevent="handlePatientDetail(scope.row)"
            >
              {{ scope.row[column.column_prop] }}
            </a>
          </template>
        </el-table-column>

        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.field_type === 'select' && column.visible === true"
          show-overflow-tooltip
        >
          <template #header>
            <el-popover placement="bottom" title="" width="230" trigger="click">
              <div slot="reference" class="search-header">
                <span class="search-title">{{
                  column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
                }}</span>
                <i
                  style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                  class="el-icon-search"
                  :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </div>
              <el-select
                v-if="'doctor_id' == column.column_prop"
                v-model="searchParams.bind_status"
                placeholder="请选择绑定状态"
                size="mini"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.bind_status)
                "
              >
                <template slot="prefix">
                  <i
                    class="el-icon-search"
                    style="width: 25px; line-height: 32px;"
                  ></i>
                </template>
                <el-option
                  v-for="item in bindStatusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-popover>
          </template>
          <template slot-scope="scope">
            <div
              style="display: flex;justify-content: space-around;align-items: center"
            >
              <el-select
                v-if="scope.row.doctor_id > 0"
                v-model="scope.row.doctor_id"
                style="width: 180px"
                size="mini"
                :disabled="scope.row.doctor_id > 0"
                filterable
                placeholder="请输入医生姓名"
                @change="handleDoctorSelect(scope.$index, scope.row)"
              >
                <el-option
                  v-for="item in doctorList"
                  :key="item.id"
                  :label="item.doctor_name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <el-select
                v-else
                v-model="doctorSelect[scope.$index]"
                style="width: 180px"
                size="mini"
                :disabled="scope.row.doctor_id > 0"
                filterable
                placeholder="请输入医生姓名"
                @change="handleDoctorSelect(scope.$index, scope.row)"
              >
                <el-option
                  v-for="item in doctorList"
                  :key="item.id"
                  :label="item.doctor_name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <el-button
                :disabled="scope.row.doctor_id > 0"
                size="mini"
                type="primary"
                icon="el-icon-connection"
                style="margin-right: 10px; padding: 7px 8px;margin-left: 5px;
				             background-color: #2362FB; border-color: #2362FB"
                @click="handleBindingDoctor(scope.$index, scope.row)"
                >绑定
              </el-button>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="
            column.field_type === 'imageBtn' && column.visible === true
          "
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div
              v-if="scope.row[column.column_prop].length > 0"
              style="font-size: 12px; color: #2379fb"
              @click="
                handleShowImage(
                  scope.row[column.column_prop],
                  scope.row[column.column_prop][0]
                )
              "
            >
              <a>点击查看图片</a>
              <el-image
                style="width: 0px; height: 0px"
                :ref="scope.row[column.column_prop][0]"
                :src="imageUrl"
                :preview-src-list="scope.row[column.column_prop]"
                :z-index="9999"
              >
              </el-image>
            </div>
            <div v-else>
              暂无
            </div>
          </template>
        </el-table-column>

        <el-table-column
          :sortable="tableHeadSortable.includes(column.column_prop)"
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.visible === true"
          show-overflow-tooltip
        >
          <template #header>
            <el-popover
              placement="bottom"
              title=""
              min-width="160"
              trigger="click"
              v-if="
                searchTableHead.filter(
                  (item) => item.name == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                  column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
                }}</span>
                <i
                  style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                  class="el-icon-search"
                  :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>

              <!--<el-input-->
              <!--	v-if="'order_count' == column.column_prop"-->
              <!--	size="mini"-->
              <!--	placeholder=""-->
              <!--	prefix-icon="el-icon-search"-->
              <!--	v-model="searchParams.buy_count"-->
              <!--	clearable-->
              <!--	@change="handleSearch(column.column_prop ,searchParams.buy_count)"-->
              <!--&gt;-->
              <!--</el-input>-->

              <el-select
                v-if="'is_health' == column.column_prop"
                v-model="searchParams.is_health"
                placeholder="请选择绑定状态"
                size="mini"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.is_health)
                "
              >
                <template slot="prefix">
                  <i
                    class="el-icon-search"
                    style="width: 25px; line-height: 32px;"
                  ></i>
                </template>
                <el-option
                  v-for="item in healthStatusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>

              <!--<el-select-->
              <!--	v-else-if="'visit_level' == column.column_prop"-->
              <!--	style="width:200px"-->
              <!--	v-model="searchParams.visit_level"-->
              <!--	placeholder="请选择"-->
              <!--	size="mini"-->
              <!--	clearable-->
              <!--	@change="handleSearch(column.column_prop ,searchParams.visit_level)"-->
              <!--&gt;-->
              <!--	<template slot="prefix">-->
              <!--		<i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>-->
              <!--	</template>-->
              <!--	<el-option-->
              <!--		v-for="item in optionsVisitLevel"-->
              <!--		:key="item.short_name"-->
              <!--		:label="item.short_name"-->
              <!--		:value="item.short_name">-->
              <!--	</el-option>-->
              <!--</el-select>-->

              <el-date-picker
                v-else-if="'order_at' == column.column_prop"
                v-model="searchParams.order_at"
                :default-time="['00:00:00', '23:59:59']"
                type="daterange"
                size="mini"
                value-format="yyyy-MM-dd HH:mm:ss "
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="
                  handleSearch(column.column_prop, searchParams.order_at)
                "
              >
              </el-date-picker>

              <el-date-picker
                v-else-if="'created_at' == column.column_prop"
                v-model="searchParams.created_at"
                :default-time="['00:00:00', '23:59:59']"
                type="daterange"
                size="mini"
                value-format="yyyy-MM-dd HH:mm:ss "
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="
                  handleSearch(column.column_prop, searchParams.created_at)
                "
              >
              </el-date-picker>

              <el-date-picker
                v-else-if="'expired_at' == column.column_prop"
                v-model="searchParams.expired_at"
                :default-time="['00:00:00', '23:59:59']"
                type="daterange"
                size="mini"
                value-format="yyyy-MM-dd HH:mm:ss "
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="
                  handleSearch(column.column_prop, searchParams.expired_at)
                "
              >
              </el-date-picker>

              <el-date-picker
                v-else-if="'end_visit_at' == column.column_prop"
                v-model="searchParams.end_visit_at"
                :default-time="['00:00:00', '23:59:59']"
                type="daterange"
                size="mini"
                value-format="yyyy-MM-dd HH:mm:ss "
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="
                  handleSearch(column.column_prop, searchParams.end_visit_at)
                "
              >
              </el-date-picker>

              <!--<el-date-picker-->
              <!--	v-else-if="'expired_at' == column.column_prop"-->
              <!--	v-model="choiceDateExpire"-->
              <!--	:default-time="['00:00:00', '23:59:59']"-->
              <!--	type="daterange"-->
              <!--	size="mini"-->
              <!--	value-format="yyyy-MM-dd HH-mm-ss "-->
              <!--	range-separator="至"-->
              <!--	start-placeholder="开始日期"-->
              <!--	end-placeholder="结束日期"-->
              <!--	@change="handleExpireChangeDate">-->
              <!--</el-date-picker>-->
            </el-popover>
            <span v-else>{{
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            }}</span>
          </template>
          <template slot-scope="scope">
            <span v-if="column.column_prop === 'examine_status'"
              >{{ scope.row[column.column_prop] == 0 ? "待审核" : "已审核" }}
            </span>

            <span v-else-if="column.column_prop === 'is_health'"
              >{{ scope.row[column.column_prop] == true ? "已填写" : "未填写" }}
            </span>

            <span v-else-if="column.column_prop === 'use_status'">
              {{ scope.row[column.column_prop] | useStatusFormat }}
            </span>
            <span v-else-if="column.column_prop === 'is_wx'">
              {{ scope.row[column.column_prop] == "0" ? "否" : "是" }}
            </span>
            <span v-else-if="column.column_prop === 'supervise_status'">
              {{ scope.row[column.column_prop] | formatSuperviseStatus }}
            </span>
            <span v-else>{{ scope.row[column.column_prop] }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>

    <!--pagination-->
    <pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />

    <!--编辑-->
    <createdOnThe v-show="establishFlag" ref="createdOnThe" />
    <editField v-show="editFlag" ref="editField" />

    <!--编辑表头-->
    <editTableHead
      :isActiveEditTableHead.sync="
        isActiveEditTableHead
      "
      @_getAdminFieldIndex="_getAdminFieldIndex"
      v-if="isActiveEditTableHead"
      :table_type="table_type"
    >
    </editTableHead>
  </div>
</template>

<script>
import {
  getThreadThrowList,
  postExamine,
  deleteThreadThrow,
  getExportThrow,
} from "@/api/media";
import { getBasicConfig } from "@/api/rank/tags";
import pagination from "@/components/Pagination";
import createdOnThe from "./components/createdOnThe";
import editField from "./components/editField";
import editTableHead from "@/components/editTableHead";
import { getAdminFieldIndex } from "@/api/ggkq";
import {mapGetters, mapState} from "vuex";
export default {
  name: "CDMCluesToSettlement",
  components: {
    pagination,
    createdOnThe,
    editField,
    editTableHead,
  },
  computed: {
    ...mapGetters(["agentId", "adminId"]),
    ...mapState({
      is_admin: state => state.user.is_admin,
      operates: state => state.user.operates,
      table_options:state=>state.user.table_options,
    }),
  },
  data() {
    return {
      table_type:'cluesToSettlement',
      searchTableHead: [
        {
          name: "order_count",
          isSearch: false,
        },
        {
          name: "is_wx",
          isSearch: false,
        },
      ],
      tableHeadSortable: [],
      choiceDateCreateRecord: [],
      choiceDateExpire: [],
      tableHead: [],
      isActiveEditTableHead: false,
      checked: null,
      currentSelectItem: {},

      editFlag: false,
      establishFlag: false, //创建投放
      //搜索信息
      inquireList: {
        date: "",
        media: "",
      },
      amountData: [],
      thread_count: {},
      tableData: [],
      listLoading: false,
      searchParams: {
        timeValue: [],
        created_at: "",
        date: "",
      },
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      total: 0,
      fromList: [],
      channelList: [],
      operateList: [],
    };
  },
  async created() {
    console.log(this.$route.path)
    console.log(this.operates)
    this.operates.forEach(operate => {
      if(operate[this.$route.path]) {
        console.log(operate[this.$route.path].split(','))
        this.operateList.push(...operate[this.$route.path].split(','))
      }
    })
    await this._getAdminFieldIndex();
    this.getList();
    this.fromData();
    this.$refs.createdOnThe.$on("getList", this.getList);
    this.$refs.editField.$on("getList", this.getList);
  },
  methods: {
    //导出
    handleExport() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      getExportThrow(this.searchParams)
        .then((response) => {
          let data = response;
          loading.close();
          if (data) {
            const xlsx = "application/vnd.ms-excel";
            const blob = new Blob([data], {
              type: xlsx,
            });
            const a = document.createElement("a"); // 转换完成，创建一个a标签用于下载
            a.download = "媒体投放表.xlsx";
            a.href = window.URL.createObjectURL(blob);
            a.click();
            a.remove();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
          this.dialogVisible3 = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //编辑
    handleEdit(row) {
      console.log(row)
      if (row.id == undefined || row == null) {
        this.$message({
          type: "error",
          message: "请先选择再编辑",
        });
        return;
      }
      this.editFlag = true;
      this.$refs.editField.editFlag = true;
      this.$refs.editField.editData = row;
    },
    //删除
    handleDel(row) {
      if (row.id == undefined || row == null) {
        this.$message({
          type: "error",
          message: "请选择要删除的数据",
        });
        return;
      }
      let id = row.id
      console.log(id);
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteThreadThrow(id).then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
            this.getList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    fromData() {
      getBasicConfig({ c_type: "from" }).then((res) => {
        if (res.code == 200) {
          this.fromList = res.data.map((item) => {
            return {
              from: item.name,
              from_id: item.id,
            };
          });
          this.$refs.editField.fromList = this.fromList;
        }
      });
      getBasicConfig({ c_type: "channel" }).then((res) => {
        if (res.code == 200) {
          this.channelList = res.data.map((item) => {
            return {
              channel: item.name,
              channel_id: item.id,
            };
          });
          this.$refs.createdOnThe.channelList = this.channelList;
          this.$refs.editField.channelList = this.channelList;
        }
      });
    },
    handleEstablish() {
      this.establishFlag = true;
      this.$refs.createdOnThe.establishFlag = this.establishFlag;
    },
    getList() {
      this.listLoading = true;
      let params = {
        ...this.searchParams,
      };
      params.page = this.listQuery.page;
      params.limit = this.listQuery.limit;
      getThreadThrowList(params).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.list;
          // this.tableData = this.tableData.map((item) => {
          //   item.diff_amount = (
          //     Number(item.throw_amount) - Number(item.unique_amount)
          //   ).toFixed(2); //差异
          //   item.account_cost = (
          //     Number(item.cost) * Number(item.account_rebate)
          //   ).toFixed(2)//账户消耗
          //   item.repeat_count = (
          //     Number(item.thread_count) - Number(item.unique_count)
          //   ).toFixed(0); //重现数量
          //   item.repeat_rate = (
          //     Number(item.repeat_count) / Number(item.thread_count)
          //   ).toFixed(2); //重复率
          //   item.unique_amount = (
          //     Number(item.unique_count) * Number(item.thread_cost)
          //   ).toFixed(2); //去重金额
          //   return item;
          // });
          this.total = res.data.total;
          this.thread_count = res.data.thread_count;
          this.listLoading = false;
          console.log(this.thread_count);
          this.amountData = [
            { name: "现金消耗", money: this.thread_count.cost, symbol: "￥" },
            {
              name: "账户消耗",
              money: this.thread_count.account_cost,
              symbol: "￥",
            },
            {
              name: "服务费用",
              money: this.thread_count.service_amount,
              symbol: "￥",
            },
            {
              name: "投放金额",
              money: this.thread_count.throw_amount,
              symbol: "￥",
            },
            { name: "去重进线", money: this.thread_count.unique_count },
          ];
        }
      });
    },
    //确认
    handleClick(row) {
      console.log(row);
      if (row.id == undefined || row == null) {
        this.$message({
          type: "warning",
          message: "请选择一条数据!",
        });
        return;
      }
      //确定框
      this.$confirm("确认通过审核吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          postExamine(row.id).then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "审核成功!",
              });
              this.getList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消审核",
          });
        });
    },
    changeDate() {
      this.searchParams.created_at = this.inquireList.date;
      if (this.inquireList.date) {
        this.searchParams.created_at = this.searchParams.created_at.join(",");
        this.getList();
      } else {
        this.getList();
      }
    },

    handleSelectionChange(val) {
      console.log(val, "val");
      //去判断按钮的显示与隐藏
      // this.tableData3.forEach(item => {
      //   // 排他,每次选择时把其他选项都清除
      //   if (item.id !== row.id) {
      //     item.checked = false
      //   }
      // })
      // console.log(row)
      this.currentSelectItem = val;
      // this.$refs.CDMSelectionOfTheService.multipleSelection = this.multipleSelection;
    },
    handleSearch(prop, value) {
      console.log(prop);
      this.searchTableHead.forEach((item) => {
        if (item.name == prop) {
          if (value == "") {
            item.isSearch = false;
          } else {
            item.isSearch = true;
          }
        }
      });
      this.getList("restPage");
    },
    handleResetSearch() {
      this.searchParams = {
        type: "today_thread",
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.getList("restPage");
    },
    async _getAdminFieldIndex() {
      // try {
      //   this.listLoading = true;
      //   var params = {
      //     type: "cluesToSettlement",
      //   };
      //   const res = await getAdminFieldIndex(params);
        this.tableHead = this.table_options.cluesToSettlement;
      //   console.log(this.tableHead);
      // } catch (err) {
      //   //在此处理错误
      // } finally {
      //   this.listLoading = false;
      // }
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.flex_1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
}
</style>
