import request from '@/utils/request'
//线索统计表
export function getThrowCountList() {
    return request({
        url: 'admin/throwCountList',
        method: 'get',
    })
}
//获取媒体投放列表
export function getThreadThrowList(params) {
    return request({
        url: 'admin/threadThrow',
        method: 'get',
        params
    })
}
//新增媒体投放
export function postThreadThrow(data) {
    return request({
        url: 'admin/threadThrow',
        method: 'post',
        data
    })
}
//审核媒体投放
export function postExamine(id) {
    return request({
        url: 'admin/threadThrow/examine/' + id,
        method: 'post',
    })
}
//删除媒体投放
export function deleteThreadThrow(id) {
    return request({
        url: 'admin/threadThrow/' + id,
        method: 'delete',
    })
}
// 编辑媒体投放
// admin/threadThrow/{id}
export function putThreadThrow(id, data) {
    return request({
        url: 'admin/threadThrow/' + id,
        method: 'put',
        data
    })
}
//导出
export function getExportThrow(query) {
    return request({
        headers: {
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'blob',
        url: 'admin/exportThrow',
        method: 'get',
        params:query
    })
}