import { render, staticRenderFns } from "./createdOnThe.vue?vue&type=template&id=73de2edd&scoped=true&"
import script from "./createdOnThe.vue?vue&type=script&lang=js&"
export * from "./createdOnThe.vue?vue&type=script&lang=js&"
import style0 from "./createdOnThe.vue?vue&type=style&index=0&id=73de2edd&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73de2edd",
  null
  
)

export default component.exports