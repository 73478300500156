<template>
  <div>
    <el-dialog
      title="编辑"
      :visible.sync="editFlag"
      width="50%"
      :before-close="handleClose"
    >
      <el-form label-width="100px" :model="formData">
        <el-form-item label="媒体名称" :required="true">
          <el-select v-model="formData.from_id" clearable placeholder="请选择" disabled>
            <el-option
              v-for="item in fromList"
              :key="item.from_id"
              :label="item.from"
              :value="item.from_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="渠道名称" :required="true">
          <el-select
            v-model="formData.channel_id"
            clearable
            placeholder="请选择"
            disabled
          >
            <el-option
              v-for="item in channelList"
              :key="item.channel_id"
              :label="item.channel"
              :value="item.channel_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="投放日期" :required="true">
          <el-date-picker
            v-model="formData.date"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="现金消耗">
          <el-input v-model="formData.cost" type="number"></el-input>
        </el-form-item>
        <el-form-item label="账户返点">
          <el-input v-model="formData.account_rebate" type="number"></el-input>
        </el-form-item>
        <el-form-item label="进线数量">
          <el-input v-model="formData.thread_count" type="number"></el-input>
        </el-form-item>
        <el-form-item label="去重进线数">
          <el-input v-model="formData.unique_count" type="number"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            :rows="4"
            placeholder="请输入内容"
            v-model="formData.remark"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { putThreadThrow } from "@/api/media/index";
export default {
  name: "editField",
  data() {
    return {
      editFlag: false,
      formData: {},
      fromList: [],
      channelList: []
    };
  },
  //监视editFlag
  watch: {
    editFlag: function(val) {
      if (val) {
        this.formData = this.editData;
      }
    },
  },
  methods: {
    handleClose() {
      this.handleCancel();
    },
    handleConfirm() {

      //将this.formData.date转换为年月日
      console.log(this);
      this.formData.date = new Date().toLocaleDateString().replace(/\//g, "-");
      const data = {
        ...this.formData,
      };
      // data.channel = this.channelList.find(
      //   (item) => item.channel_id === data.channel_id
      // ).channel;
      console.log(data);
      putThreadThrow(this.editData.id, data).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.editFlag = false;
          this.$emit("getList");
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    handleCancel() {
      this.editFlag = false;
      // this.$emit("getList");
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/ input[type="number"] {
  -moz-appearance: textfield;
}
</style>
