import request from '@/utils/request'

//标签列表
export function getBasicConfig(params) {
  return request({
    url: 'admin/basicConfig',
    method: 'get',
    params
  })
}
//修改状态

export function putStatus(id,data) {
  return request({
    url: 'admin/basicConfig/status/' + id,
    method: 'post',
    data,
  })
}
//添加配置

export function postBasicConfig(data) {
  return request({
    url: 'admin/basicConfig',
    method: 'POST',
    data,
  })
}
//修改配置
export function putBasicConfig(id,data) {
  return request({
    url: `admin/basicConfig/${id}`,
    method: 'PUT',
    data,
  })
}
//删除
export function deleteBasicConfig(id) {
  return request({
    url: `admin/basicConfig/` + id,
    method: 'DELETE',
  })
}
//抛单列表
export function getThrowCountList(params) {
  return request({
    url: 'admin/throwCountList',
    method: 'get',
    params
  })
}